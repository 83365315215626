import React, { useState, useEffect, useCallback } from 'react';
import './App.css';
import RecordCard from './RecordCard';
import { useNavigate } from 'react-router-dom';

function App() {
  const vinQueryName = 'vin';
  const navigate = useNavigate();
  // get base url from ENV
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [vin, setVin] = useState('');
  const [records, setRecords] = useState([]);
  const [isNoRecords, setIsNoRecords] = useState(false); 
  const [images, setImages] = useState([]);
  const [isFetchingRecords, setIsFetchingRecords] = useState(false); // Loading state for records
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false); // Add sidebar collapsed state

  const toggleSidebar = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  const clearRecords = () => {
    setRecords([]);
    setImages([]);
  };

  const fetchRecords = useCallback(async (vinValue) => {
    try {
      clearRecords();
      setIsFetchingRecords(true);
      setIsNoRecords(false);

      navigate(`?vin=${vinValue}`);

      const response = await fetch(`${baseUrl}/api/bv/get_records?vin=${vinValue}`);
      const data = await response.json();
      setRecords(data.records || []);
      setIsNoRecords(data.records.length === 0);
    } catch (error) {
      console.error(error);
    } finally {
      setIsFetchingRecords(false);
    }
  }, [baseUrl, navigate]);

  const fetchImages = async (record) => {
    try {
      setImages([])

      const response = await fetch(`${baseUrl}/api/bv/get_images`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(record)
      });
      const data = await response.json();
      setImages(data.images || []);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const vinFromUrl = queryParams.get(vinQueryName);
    if (vinFromUrl) {
      setVin(vinFromUrl);
      fetchRecords(vinFromUrl); // Automatically fetch records if VIN is in the URL
    }
  }, [fetchRecords]);

  return (
    <div className="App">
      <div className="header">
        <h1>Carsit</h1>
        <p>
          <input
            type="text"
            placeholder="Enter VIN"
            value={vin}
            onChange={(e) => setVin(e.target.value)}
          />
          <button onClick={() => fetchRecords(vin)} disabled={isFetchingRecords}>
            {isFetchingRecords ? 'Fetching...' : 'Fetch Records'}
          </button>
        </p>
        <button className='no-margin' onClick={toggleSidebar}>
          {isSidebarCollapsed ? 'Show Records' : 'Hide Records'}
        </button>
      </div>
      <div className="container">
        <div className={`sidebar ${isSidebarCollapsed ? 'collapsed' : ''}`}>
          <div className={isSidebarCollapsed ? 'collapsed' : ''}>
            {isNoRecords && (
              <p className="no-records">No records found</p>
            )}
            <h2>Records</h2>
            <div className="records">
              {records.map((record, index) => (
                <RecordCard
                  key={index}
                  record={record}
                  index={index}
                  fetchImages={fetchImages}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="images">
          <ul>
            {images.map((image, index) => (
              <li key={index}>
                <img src={image} alt={`${index}`} referrerPolicy="no-referrer" />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default App;