import React from 'react';

function RecordCard({ record, index, fetchImages }) {
  const [isFetchingImages, setIsFetchingImages] = React.useState(false);
  const [isInfoCollapsed, setIsInfoCollapsed] = React.useState(true);

  const toggleInfo = () => {
    setIsInfoCollapsed(!isInfoCollapsed);
  };

  const weFetchImages = async (record) => {
    setIsFetchingImages(true);
    await fetchImages(record);
    setIsFetchingImages(false);
  }

  return (
    <div className="card">
      <h3>Sale Record {index + 1}</h3>
      <div className="record-info">
        <button onClick={toggleInfo}>
          {isInfoCollapsed ? 'Show Info' : 'Hide Info'}
        </button>
        {!isInfoCollapsed && (
          <>
            <p>URL: {record.url}</p>
            {Object.keys(record.data).map((key, index) => (
              <p key={index}>
                {key}: {record.data[key]}
              </p>
            ))}
          </>
        )}
      </div>
      <img
        className="record-image"
        src={record.first_photo_url}
        alt={`Record ${index}`}
        referrerPolicy="no-referrer"
      />
      <button
        onClick={() => weFetchImages(record)}
        disabled={isFetchingImages}
      >
        {isFetchingImages ? 'Fetching...' : `Fetch ${record.image_count} photos`}
      </button>
    </div>
  );
}

export default RecordCard;
